import React from 'react';
import { Users, Building2, Image, ShieldAlert } from "lucide-react"
import './UseCases.css';
import { useTranslation } from 'react-i18next';

export function UseCases() {
  const { t } = useTranslation();

  const useCases = [
    {
      icon: Users,
      title: t("recruitmentFraudPrevention"),
      description: t("recruitmentFraudPreventionDescription"),
      color: "from-blue-500/20 to-blue-500/0",
    },
    {
      icon: Building2,
      title: t("vendorAndThirdPartySecurity"),
      description: t("vendorAndThirdPartySecurityDescription"),
      color: "from-green-500/20 to-green-500/0",
    },
    {
      icon: Image,
      title: t("ITRequestAuthentication"),
      description: t("ITRequestAuthenticationDescription"),
      color: "from-purple-500/20 to-purple-500/0",
    },
    {
      icon: ShieldAlert,
      title: t("fraudPrevention"),
      description: t("fraudPreventionDescription"),
      color: "from-red-500/20 to-red-500/0",
    },
  ]

  return (
    <section className="container py-24 space-y-16" id="use-cases">
      <div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        className="text-center space-y-4"
      >
        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">{t('strategicUseCases')}</h2>
        <p className="mx-auto max-w-[700px] text-muted-foreground text-lg">
          {t('strategicUseCasesSubtitle')}
        </p>
      </div>

      <div className="use-cases-grid">
        {useCases.map((useCase, index) => (
          <div
            key={useCase.title}
            className="use-case-card"
          >
            <div className="relative overflow-hidden h-full">
              <div className={`absolute inset-0 bg-gradient-to-br ${useCase.color} opacity-50`} />
                <useCase.icon className="h-8 w-8 text-primary" />
                <h3>{useCase.title}</h3>
                <p className="text-gray-600">{useCase.description}</p>
                { /* Learn how Validia can help protect your {useCase.title.toLowerCase()} operations. */ }
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default UseCases;
