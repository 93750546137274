import React from 'react';
import './Integrations.css';
import { useTranslation } from 'react-i18next';
import '../../i18n'; // Import the i18n configuration

function Integrations() {
    const { t } = useTranslation();

    const integrations = [
      { name: 'Gusto', logo: 'integrations/gusto.png' },
      { name: 'BambooHR', logo: 'integrations/bamboo.png' },
      { name: 'Greenhouse', logo: 'integrations/greenhouse.png' },
      { name: 'Workday', logo: 'integrations/workday.png' },
      { name: 'Ashby', logo: 'integrations/ashby.png' },
      { name: 'ServiceNow', logo: 'integrations/servicenow.svg' },
      { name: 'ADP', logo: 'integrations/adp.png' },
      { name: 'Deel', logo: 'integrations/deel.png' },
      { name: 'SuccessFactors', logo: 'integrations/sap.png' },
      { name: 'UKG', logo: 'integrations/ukg.png' },
      { name: 'Salesforce', logo: 'integrations/salesforce.png' },
      { name: 'Zendesk', logo: 'integrations/zendesk.png' },
      { name: 'Okta', logo: 'integrations/okta.png' },
      { name: 'Box', logo: 'integrations/box.png' },
      { name: 'OneDrive', logo: 'integrations/one.png' },
      { name: 'DropBox', logo: 'integrations/drop.png' },
      { name: 'Drive', logo: 'integrations/drive.png' },
      { name: 'Sharepoint', logo: 'integrations/sharepoint.png' },
      { name: 'Calendar', logo: 'integrations/calendar.png' },
      { name: 'Outlook', logo: 'integrations/outlook.jpeg' },
    ];

    const formatClassName = (name) =>
      name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');

    return (
      <div className="integrations-container">
        <h2>{t('enterpriseIntegrations')}</h2>
        <div className="integrations-scroll">
          <div className="integration-item-set integration-item-primary-set">
              {integrations.map((integration, index) => (
                <div key={index} className="integration-item integration-item-primary">
                  <img
                    src={integration.logo}
                    alt={`${integration.name} logo`}
                    className={`${formatClassName(integration.name)}-logo integration-logo`}
                  />
                  <p>{integration.name}</p>
                </div>
            ))}
          </div>
          {/* Duplicate cards for seamless looping */}
          <div className="integration-item-set integration-item-secondary-set">
              {integrations.map((integration, index) => (
                <div key={index} className="integration-item integration-item-secondary">
                  <img
                    src={integration.logo}
                    alt={`${integration.name} logo`}
                    className={`${formatClassName(integration.name)}-logo integration-logo`}
                  />
                  <p>{integration.name}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }


export default Integrations;
