import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { FaArrowRight, FaEnvelope, FaPhone } from 'react-icons/fa';
import './Contact.css';

function Testimonials() {
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <section id="contact" className="contact-section">
        	<h2>{t('getInTouch')}</h2>
        	<p className="contact-intro">{t('contactIntro')}</p>
        	<div className="contact-container">
        		<form className="contact-form" action="https://formspree.io/f/xyzgjaee" method="POST">
        			<div className="form-group">
        				<input
        					type="text"
        					name="name"
        					value={formData.name}
        					onChange={handleInputChange}
        					required
        					aria-label={t('yourName')}
        					/>
        				<label>{t('yourName')}</label>
        			</div>
        			<div className="form-group">
        				<input
        					type="email"
        					name="email"
        					value={formData.email}
        					onChange={handleInputChange}
        					required
        					aria-label={t('yourEmail')}
        					/>
        				<label>{t('yourEmail')}</label>
        			</div>
        			<div className="form-group">
        				<textarea
        					name="message"
        					value={formData.message}
        					onChange={handleInputChange}
        					required
        					aria-label={t('yourMessage')}
        					></textarea>
        				<label>{t('yourMessage')}</label>
        			</div>
        			<button type="submit" className="submit-button">
        				{t('sendMessage')}
        				<FaArrowRight />
        			</button>
        		</form>
        		<div className="contact-info">
        			<h3>{t('contactInformation')}</h3>
        			<p>{t('contactDescription')}</p>
        			<ul>
        				<li>
        					<FaEnvelope />
        					<a href="mailto:justin@validia.ai">justin@validia.ai</a>
        				</li>
        				<li>
        					<FaPhone />
        					<a href="tel:+15402870222">+1 (540) 287-0222</a>
        				</li>
        			</ul>
        		</div>
        	</div>
        </section>
    );
}

export default Testimonials;
