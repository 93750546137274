import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { FaBuilding, FaIndustry, FaDatabase } from 'react-icons/fa';
import './Testimonials.css';

function Testimonials() {
    const { t } = useTranslation();

    const [activeTestimonial, setActiveTestimonial] = useState('government');

    const testimonials = {
      government: {
        title: t('governmentTitle'),
        icon: <FaBuilding />,
        text: t('governmentTestimonial'),
        author: t('governmentAuthor')
      },
      dataRecovery: {
        title: t('corporateSecurityTitle'),
        icon: <FaDatabase />,
        text: t('corporateSecurityTestimonial'),
        author: t('corporateSecurityAuthor')
      },
      industry: {
        title: t('internetMonitoringTitle'),
        icon: <FaIndustry />,
        text: t('internetMonitoringTestimonial'),
        author: t('internetMonitoringAuthor')
      }
    };

    return (
        <section id="testimonials" className="testimonial-showcase">
            <div className="testimonial-content">
                <h2>{t('partnerTestimonials')}</h2>
                <div className="testimonial-selector">
                    {Object.keys(testimonials).map((key) => (
                        <div
                        key={key}
                        className={`selector-item ${activeTestimonial === key ? 'active' : ''}`}
                        onClick={() => setActiveTestimonial(key)}
                        >
                            {testimonials[key].icon}
                            <span>{testimonials[key].title}</span>
                        </div>
                    ))}
                </div>
                <div className="testimonial-display">
                    <p className="testimonial-text">{testimonials[activeTestimonial].text}</p>
                    <p className="testimonial-author">— {testimonials[activeTestimonial].author}</p>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
