import React from 'react';
import { useEffect } from 'react';

// External redirect component.
const ExternalRedirect = () => {
    const redirectUrl = "https://calendly.com/d/cnf8-mcr-v2y/validia-demo-call-website";

    useEffect(() => {
        window.location.href = redirectUrl;
    }, []);

    return <p>Redirecting to <a href={redirectUrl}>{redirectUrl}</a>...</p>;
};

export default ExternalRedirect;
