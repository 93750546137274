import type React from "react"
import { useTranslation } from "react-i18next"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import Button from '@mui/material/Button';
import "./Hero.css"

export default function Hero() {
  const { t } = useTranslation();

  const integrationComponent = (
      <div className="integration-logos">
        <img alt="Zoom logo" src={"meeting-platforms/zoom.png"} style={{ maxHeight: "50px" }} />
        <img alt="Microsoft Teams logo" src={"meeting-platforms/teams.png"} style={{ maxHeight: "50px", paddingRight: '30px' }} />
        <img alt="Cisco Webex logo" src={"meeting-platforms/webex.png"} style={{ maxHeight: "40px", paddingRight: '30px' }} />
        <img alt="Slack logo" src={"meeting-platforms/slack.png"} style={{ maxHeight: '50px', paddingRight: '10px' }} />
        <img alt="Google Meet logo" src={"meeting-platforms/meet.png"} style={{ maxHeight: '50px', marginTop: "5px" }} />
      </div>
  );

  return (
      <section className="hero">
        <div className="hero-content-container">
            <div className="hero-content hero-content-left">
              <h1>{t('welcome')}</h1>
              <p>{t('subtitle')}</p>
              <Button variant="contained" disableElevation className="primary-button" onClick={() => window.location.href = 'https://calendly.com/d/494-3xs-7yt/identifai-30-minute-meeting'}>{t('bookDemoNow')}<ArrowForwardIcon style={{ paddingLeft: "10px" }} /></Button>
            </div>
            <div className="hero-content hero-content-right">
              <video autoPlay muted loop playsInline className="hero-video">
                <source src="hero-video.mov" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
        </div>
        <div className="hero-bottom">
          <p>{t('weSecure')}</p> {integrationComponent}
        </div>
      </section>
  )
}
