import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import '../i18n'; // Import the i18n configuration
import '../App.css';
import BelowHeaderBanner from './home/BelowHeaderBanner'
import FAQ from './home/FAQ';
import AboutUs from './home/AboutUs';
import DemoViewer from './home/ArcadeEmbed';
import FlashingStats from './home/FlashingStats';
import KnowYour from './home/KnowYour';
import UseCases from './home/UseCases';
import Integrations from './home/Integrations';
import BlogTeaser from './home/BlogTeaser';
import Hero from './home/Hero';
import './Home.css';
import Contact from './home/Contact';
import Testimonials from './home/Testimonials';
import StructuredData from './home/StructuredData';

function HomePage() {

  const { t } = useTranslation();

  // New function for lazy loading images
  const lazyLoad = (target) => {
    const io = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const src = img.getAttribute('data-src');
          img.setAttribute('src', src);
          img.classList.add('fade');
          observer.disconnect();
        }
      });
    });
    io.observe(target);
  };

  // Use effect for lazy loading
  useEffect(() => {
    const images = document.querySelectorAll('[data-src]');
    images.forEach(lazyLoad);
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Validia - {t('welcome')}</title>
        <meta name="description" content="Validia protects against deepfakes and AI impersonation, securing enterprise digital identity in the age of adversarial AI." />
        <meta property="og:title" content="Validia - Securing Enterprise Digital Identity" />
        <meta property="og:description" content="Protect against deepfakes and AI impersonation with Validia" />
        <meta property="og:image" content="https://validia.ai/og-image.jpg" />
        <meta property="og:url" content="https://validia.ai" />
        <link rel="canonical" href="https://validia.ai" />
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/validia_icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@validia_" />
        <meta name="twitter:title" content="Validia - Securing Enterprise Digital Identity" />
        <meta name="twitter:description" content="Protect your enterprise from deepfake threats with Validia's advanced AI solutions. Secure your digital identity today." />
        <meta name="twitter:image" content="https://validia.ai/twitter-image.png" />
      </Helmet>

      <BelowHeaderBanner></BelowHeaderBanner>

      <main>
        <Hero/>
        <Integrations />
        <FlashingStats />
        <section className="unified-platform">
          <h2>{t('unifiedPlatform')}</h2>
          <KnowYour />
          <DemoViewer />
          <UseCases />
        </section>
        <Testimonials />
        <BlogTeaser />
        <AboutUs />
        <FAQ />
        <Contact/>
      </main>

      <StructuredData/>
    </div>
  );
}

export default HomePage;
