import React from "react";

function StructuredData() {
    return (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Validia",
            "url": "https://validia.ai",
            "logo": "https://validia.ai/validia_icon.png",
            "description": "Validia protects against deepfakes and AI impersonation, securing enterprise digital identity in the age of adversarial AI.",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+15402870222",
              "contactType": "customer support",
              "email": "justin@validia.ai"
            },
            "sameAs": [
              "https://www.linkedin.com/company/validia-ai",
              "https://x.com/validia_"
            ]
          })}
        </script>
    );
}

export default StructuredData;
